<template>
  <ProjectPageTemplate
  style="position: relative; height: 100%; width: 100%;"

    :hideHeader="false"
    :title="$t(`${$route.meta.i18n}.title`)"
    :error="false"
    :loading="false"
    loadingText=""
    :isSettings="true"
  >
    <template v-slot:content>
      <div
        class="pt-2 pl-3 pr-3 box"
        id="table-project-info"
        v-if="project.general != null"
      >
        <TableListRow class="mt-2 ml-1">
          <template v-slot:key>
            {{ $t('tools.project.settings.table.projectName') }}
          </template>

          <template v-slot:value>
            <span class="font-weight-bold" style="font-size: 15px">{{
              project.general.name
            }}</span>
          </template>
        </TableListRow>

        <TableListRow class="ml-1">
          <template v-slot:key>
            {{ $t('tools.project.settings.table.projectId') }}
          </template>

          <template v-slot:value>
            <span class="font-weight-bold">{{ project.general.id }}</span>
          </template>
        </TableListRow>

        <TableListRow class="ml-1">
          <template v-slot:key>
            {{ $t('tools.project.settings.table.creationDate') }}
          </template>

          <template v-slot:value>
            <span class="font-weight-bold">{{
              $helper.formatDate(project.general.createdAt, true, false)
            }}</span>
          </template>
        </TableListRow>

        <TableListRow class="ml-1">
          <template v-slot:key>
            {{ $t('tools.project.settings.table.functions') }}
          </template>

          <template v-slot:value>
            <v-row
              class="ma-0 font-weight-bold"
              align="center"
              style="user-select: none"
            >
              <v-chip
                v-if="project.general.allowRecordings"
                small
                class="black--text elevation-0 mr-2"
                style="pointer-events: none"
              >
                {{
                  $t(
                    'tools.project.settings.table.functionsTypes.allowRecordings',
                  )
                }}</v-chip
              >
              <v-chip
                v-if="project.general.allowArming"
                small
                class="black--text elevation-0 mr-2"
                style="pointer-events: none"
              >
                {{
                  $t('tools.project.settings.table.functionsTypes.allowArming')
                }}</v-chip
              >
              <v-chip
                v-if="project.general.allowParkControl"
                small
                class="black--text elevation-0 mr-2"
                style="pointer-events: none"
              >
                {{
                  $t(
                    'tools.project.settings.table.functionsTypes.allowParkControl',
                  )
                }}</v-chip
              >
            </v-row>
          </template>
        </TableListRow>

        <!-- <v-divider class="mr-3 ml-3 mt-4 mb-4"/> -->

        <TableListRow class="mt-5 ml-1">
          <template v-slot:key>
            {{ $t('tools.project.settings.table.timezone') }}
          </template>

          <template v-slot:value>
            <div
              class="font-weight-bold"
              style="width: 100%"
              v-if="project.general.timezone != null"
            >
              {{ project.general.timezone }}
            </div>
            <span v-else>-</span>
          </template>
        </TableListRow>

        <TableListRow class="ml-1">
          <template v-slot:key>
            {{ $t('tools.project.settings.table.address') }}
          </template>

          <template v-slot:value>
            <span
              :style="
                project.general.longitude == null
                  ? 'pointer-events: none; cursor: default!important'
                  : ''
              "
              v-on:click="
                $helper.openTab(
                  `https://www.google.com/maps/search/${project.general.latitude},+${project.general.longitude}`,
                )
              "
              class="link font-weight-bold"
            >
              <div style="width: 100%">
                {{ project.general.streetName }},
                {{ `${project.general.zipCode} ${project.general.city}` }}
              </div>
            </span>
          </template>
        </TableListRow>
        <v-spacer />
        <div
          class="mt-4 mr-2"
          style="
            position: absolute;
            bottom: 10px;
            height: calc(100% - 60px - 190px);
            width: calc(100% - 24px);
            border-radius: 10px !important;
            overflow: hidden;
            background-color: transparent;
          "
        >
          <Map
            :address="{
              street: project.general.streetName,
              zip: project.general.zipCode,
              city: project.general.city,
            }"
            :marker="true"
            :center="{
              lat: project.general.latitude,
              lng: project.general.longitude,
            }"
            :zoom="17"
            style="height: 100%; width: 100%; border-radius: 10px !important"
          />
        </div>
      </div>
    </template>
  </ProjectPageTemplate>
</template>

<script>
import ProjectPageTemplate from '@/components/tools/project/ProjectPageTemplate.vue';

import TableListRow from '@components/table/TableListRow.vue';
import Map from '@components/maps/Map.vue';

export default {
  components: {
    // TextButton,
    ProjectPageTemplate,
    TableListRow,
    Map,
  },

  data() {
    return {};
  },

    computed: {
      project() {
        return this.$store.getters.project
      }
    },
  }
</script>

<style>
.link {
  border-bottom: 1px solid transparent;
  /* background-color: red; */
  cursor: pointer;
}

.link:hover {
  color: var(--v-primary-base);

  text-decoration: underline var(--v-primary-base);
}

.box {
  position: relative;
  user-select: none;
  height: 100%;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
</style>
