<template>
  <div  style="height: 100%; width: 100%;">
    <v-overlay opacity=".7" absolute :value="!center">

      <NoDataTemplate iconColor="white" text="Keine Kartendaten vorhanden." icon="mdi-map-marker-off"/>
    </v-overlay>
      <!-- <div v-show="address != null" class="elevation-2 pl-3 pr-4" style="height: 40px; user-select: none;  background-color: rgba(240, 240, 240, 1); z-index: 10; top: 10px; left: 10px;  position: absolute; border-radius: 10px; line-height: 40px; text-align: center;">
        <v-icon class="mr-1" style="margin-bottom: 3px">mdi-map-marker</v-icon><span class="font-weight-bold">{{address.street}}</span>, <span class="ml-1">{{address.zip}} {{address.city}}</span>
      </div> -->
  <GmapMap

                :center="center == null ? { lat: 48.135029, lng: 11.581935 } : center"
                :zoom="zoom"
                :options="{
                  zoomControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  disableDefaultUi: false,
                  styles: mapStyle,
                }"
                map-type-id="terrain"
                :style="!center ? 'opacity: .3' : ''"
                style=" border-radius: 10px!important; height: 100%; width: 100%;"
              >

                <gmap-custom-marker
                  v-show="marker"
                  :marker="center"
                >
                  <div
                    style="
                      height: 30px;
                      width: 30px;
                      border-radius: 15px;
                      cursor: default;
                    "
                    class="primary"
                  ></div>
                </gmap-custom-marker>
              </GmapMap>

      </div>

</template>

<script>
import NoDataTemplate from '../page/NoDataTemplate.vue'
import GmapCustomMarker from "vue2-gmap-custom-marker";


  export default {
    props: {
      address: {
        type: Object
      },
      marker: {
        type: Boolean,
        default: false
      },
      center: {
        type: Object,
        default: undefined
      },

      zoom: {
        type: Number,
        default: 12
      }
    },
    components: {
      NoDataTemplate,
      "gmap-custom-marker": GmapCustomMarker,
      // GmapCustomMarker
    },

    data() {
      return {

        mapStyle: [
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.attraction",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.business",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.government",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.medical",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "poi.place_of_worship",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.school",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.sports_complex",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
      }
    }
  }

</script>
