var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%","width":"100%"}},[_c('v-overlay',{attrs:{"opacity":".7","absolute":"","value":!_vm.center}},[_c('NoDataTemplate',{attrs:{"iconColor":"white","text":"Keine Kartendaten vorhanden.","icon":"mdi-map-marker-off"}})],1),_c('GmapMap',{staticStyle:{"border-radius":"10px!important","height":"100%","width":"100%"},style:(!_vm.center ? 'opacity: .3' : ''),attrs:{"center":_vm.center == null ? { lat: 48.135029, lng: 11.581935 } : _vm.center,"zoom":_vm.zoom,"options":{
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                disableDefaultUi: false,
                styles: _vm.mapStyle,
              },"map-type-id":"terrain"}},[_c('gmap-custom-marker',{directives:[{name:"show",rawName:"v-show",value:(_vm.marker),expression:"marker"}],attrs:{"marker":_vm.center}},[_c('div',{staticClass:"primary",staticStyle:{"height":"30px","width":"30px","border-radius":"15px","cursor":"default"}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }